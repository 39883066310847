const TOGGLE_PROCESS_INDICATOR = 'TOGGLE_PROCESS_INDICATOR'

export const toggleProcessIndicator = (show = false) => ({ type: TOGGLE_PROCESS_INDICATOR, payload: show })

const INITIAL_APP_STATE = { showProcessIndicator: false, toastData: [] }
export type IApp = typeof INITIAL_APP_STATE

type Reducer = (state: IApp, action: { type: string; payload: any }) => IApp
const appReducer: Reducer = (state = INITIAL_APP_STATE, action) => {
  switch (action.type) {
    case TOGGLE_PROCESS_INDICATOR:
      return { ...state, showProcessIndicator: action.payload }
    case 'SET_TOAST':
      const attachedIDonPayload = { ...action.payload, ...{ id: Math.random().toString(16).slice(3) } }
      return { ...state, toastData: attachedIDonPayload }
    default:
      return state
  }
}

export default appReducer
