import '../../../styles/authStyle/spinner/spinner.css'

function Spinner() {
  return (
    <>
      <div className='loadingContainer'>
        <div className='loader'></div>
        <div className='loading'>Loading</div>
      </div>
    </>
  )
}

export default Spinner
