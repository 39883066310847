const SET_USERS = 'SET_USERS'
const UPDATE_USERS = 'UPDATE_USERS'

export const setUsers = (payload: object) => ({ type: SET_USERS, payload })
export const updateUsers = (payload: object) => ({ type: UPDATE_USERS, payload })

type Item = { _id: string; [key: string]: any }

const INITIAL_STATE = {
  users: null as null | Item[],
}

type Reducer = (
  state: typeof INITIAL_STATE,
  action: { type: string; payload: Partial<typeof INITIAL_STATE> }
) => Partial<typeof INITIAL_STATE>
const usersReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USERS:
      return action.payload
    case UPDATE_USERS: {
      return action.payload
    }
    default:
      return state
  }
}

export default usersReducer
