import type { IUser } from '../../types'

const SET_USER = 'SET_USER'
const UPDATE_USER = 'UPDATE_USER'

export const setUser = (payload: object) => ({ type: SET_USER, payload })
export const updateUser = (payload: object) => ({ type: UPDATE_USER, payload })

const INITIAL_STATE: null | IUser = null

type Reducer = (
  state: typeof INITIAL_STATE,
  action: { type: string; payload: Partial<typeof INITIAL_STATE> }
) => Partial<typeof INITIAL_STATE>
const userReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload
    case UPDATE_USER:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default userReducer
