import type { ITranslator } from '../../types'

const SET_TRANSLATOR = 'SET_TRANSLATOR'
const UPDATE_TRANSLATOR = 'UPDATE_TRANSLATOR'

export const setTranslator = (payload: object) => ({ type: SET_TRANSLATOR, payload })
export const updateTranslator = (payload: object) => ({ type: UPDATE_TRANSLATOR, payload })

const INITIAL_STATE: null | ITranslator = null

type Reducer = (
  state: typeof INITIAL_STATE,
  action: { type: string; payload: Partial<typeof INITIAL_STATE> }
) => Partial<typeof INITIAL_STATE>
const translatorReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TRANSLATOR:
      return action.payload
    case UPDATE_TRANSLATOR:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default translatorReducer
