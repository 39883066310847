const SET_DUBBEDVIDEODETAILS = 'SET_DUBBEDVIDEODETAILS'
const UPDATE_DUBBEDVIDEODETAILS = 'UPDATE_DUBBEDVIDEODETAILS'

export const setDubbedVideoDetails = (payload: object | null) => ({ type: SET_DUBBEDVIDEODETAILS, payload })
export const updateDubbedVideoDetails = (payload: object | null) => ({ type: UPDATE_DUBBEDVIDEODETAILS, payload })

const INITIAL_STATE: null | any = null

type Reducer = (
  state: typeof INITIAL_STATE,
  action: { type: string; payload: Partial<typeof INITIAL_STATE> }
) => Partial<typeof INITIAL_STATE>
const dubbedVideoDetailsReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DUBBEDVIDEODETAILS:
      return action.payload
    case UPDATE_DUBBEDVIDEODETAILS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default dubbedVideoDetailsReducer
