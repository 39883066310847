import React, { Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'

import { RouteProps, useHistory } from 'react-router-dom'
import type { IAuth } from '../store/reducers/auth'

import DashboardRouter from './dashboard'
import Confirmation from '../pages/Auth/Confirmation'
import InvalidLink from '../pages/Auth/InvalidLink'
import Spinner from '../pages/Auth/Spinner'
import Onboarding4 from '../pages/Dashboard/Onboarding'

const AuthLayout = React.lazy(() => import('../pages/Auth/_layout'))
const Login = React.lazy(() => import('../pages/Auth/Login'))
const Signup = React.lazy(() => import('../pages/Auth/Signup'))
const ForgotPassword = React.lazy(() => import('../pages/Auth/ForgotPassword'))
const ResetPassword = React.lazy(() => import('../pages/Auth/ResetPassword'))
const ExternalConsent = React.lazy(() => import('../pages/Dashboard/Overview/components/ExternalConsent'))
const DubbedVideoDetails = React.lazy(() => import('../pages/Dashboard/VideoEditor'))

const ProtectedRoute: React.FunctionComponent<RouteProps> = ({ children, ...rest }) => {
  let location = useLocation<{ from: string }>()
  let { from } = location.state || { from: { pathname: '/' } }
  const history = useHistory()
  const { isLoggedIn } = useSelector<{ auth: IAuth }, IAuth>(state => state.auth)
  const company: any = useSelector<any>(state => state.company)

  useEffect(() => {
    if (isLoggedIn && company?.isEmailVerified === false) {
      history.push('/')
    }
  }, [isLoggedIn, company, history.location.pathname])

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const { pathname } = location
        return pathname === '/login' || pathname === '/signup' ? (
          isLoggedIn ? (
            <Redirect to={from} />
          ) : (
            children
          )
        ) : isLoggedIn ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }}
    />
  )
}

const RootRouter = () => {
  const hideIntercom = () => {
    const intercomContainer: any = document.getElementById('intercom-container')
    if (intercomContainer) {
      intercomContainer.classList.add('intercom-hide-launcher')
    }
  }
  return (
    <BrowserRouter>
      <IntercomProvider appId={'vw5sgx9e'} onHide={hideIntercom}>
        <Switch>
          <Route path='/reset-password' exact>
            <Suspense fallback={<Spinner />}>
              <AuthLayout>
                <Suspense fallback={<Spinner />}>
                  <ResetPassword />
                </Suspense>
              </AuthLayout>
            </Suspense>
          </Route>

          <Route path='/password-confirmation' exact>
            <Suspense fallback={<Spinner />}>
              <AuthLayout>
                <Suspense fallback={<Spinner />}>
                  <Confirmation />
                </Suspense>
              </AuthLayout>
            </Suspense>
          </Route>

          <Route path='/invalid-link' exact>
            <Suspense fallback={<Spinner />}>
              <AuthLayout>
                <Suspense fallback={<Spinner />}>
                  <InvalidLink />
                </Suspense>
              </AuthLayout>
            </Suspense>
          </Route>

          <Route path='/onboarding4' exact>
            <Suspense fallback={<Spinner />}>
              <AuthLayout>
                <Suspense fallback={<Spinner />}>
                  <Onboarding4 />
                </Suspense>
              </AuthLayout>
            </Suspense>
          </Route>

          <Route path='/loader' exact>
            <Suspense fallback={<div>Loading layout...</div>}>
              <AuthLayout>
                <Suspense fallback={<div>Loading...</div>}>
                  <Spinner />
                </Suspense>
              </AuthLayout>
            </Suspense>
          </Route>

          <Route path='/forgot-password' exact>
            <Suspense fallback={<Spinner />}>
              <AuthLayout>
                <Suspense fallback={<Spinner />}>
                  <ForgotPassword />
                </Suspense>
              </AuthLayout>
            </Suspense>
          </Route>
          <ProtectedRoute path='/login' exact>
            <Suspense fallback={<Spinner />}>
              <AuthLayout>
                <Suspense fallback={<Spinner />}>
                  <Login />
                </Suspense>
              </AuthLayout>
            </Suspense>
          </ProtectedRoute>
          <ProtectedRoute path='/signup' exact>
            <Suspense fallback={<Spinner />}>
              <AuthLayout>
                <Suspense fallback={<Spinner />}>
                  <Signup />
                </Suspense>
              </AuthLayout>
            </Suspense>
          </ProtectedRoute>
          <Route path='/external-consent/:voiceId' exact>
            <Suspense fallback={<Spinner />}>
              <ExternalConsent />
            </Suspense>
          </Route>
          <Route path='/share/:videoId' exact>
            <Suspense fallback={<Spinner />}>
              <AuthLayout>
                <DubbedVideoDetails isWritable={false} />
              </AuthLayout>
            </Suspense>
          </Route>
          <ProtectedRoute path='/'>
            <DashboardRouter />
          </ProtectedRoute>
        </Switch>
      </IntercomProvider>
    </BrowserRouter>
  )
}

export default RootRouter
