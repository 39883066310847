import React, { useEffect } from 'react'
import '../../../styles/authStyle/invalidLink/invalidlink.css'
import EmailImg from '../../../assets/images/email2.png'

function InvalidLink() {
  useEffect(() => {
    document.title = 'dubpro.ai | Invalid Link'
  }, [])

  return (
    <>
      <div className='backgrounds'></div>
      <div className='headContainers'>
        <div className='box'>
          <img src={EmailImg} className='images' alt='email'></img>
          <h1 className='title'>Invalid Reset Link</h1>
          <p className='infos'>Your link is no longer valid. Please return to sign in to reset your password again.</p>
          <a href='/' className='btnss'>
            Return to sign in{' '}
          </a>
        </div>
      </div>
    </>
  )
}

export default InvalidLink
