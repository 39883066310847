import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'

const useStyles = createUseStyles({
  image: {
    height: 'auto',
    width: 'auto',
    maxWidth: '360px !important',
    margin: 'auto',
  },
  message: {
    margin: '1rem 0px 0px 0px !important',
  },
  NotificationContainer: {
    fontSize: '14px',
    boxSizing: 'border-box',
    position: 'fixed',
    width: '340px',
    zIndex: 999,
  },
  ToastBox: {
    padding: '10px',
    background: '#56C568',
    borderRadius: '5px',
    color: 'white',
    marginBottom: '15px',
  },
  TopRight: {
    top: '12px',
    right: '12px',
    transition: 'transform .6s ease-in-out',
    animation: 'toast-in-right .7s',
  },
  Flex: {
    display: 'flex',
    alignItems: 'center',
  },
  FlexColumn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  FlexAround: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  Margin: {
    marginRight: '10px',
  },
})

interface IToast {
  message: string
  id: number
  status: string
}

const Toast = () => {
  const toastData = useSelector((store: any) => store?.app?.toastData)
  const [toastList, setToastList] = useState<IToast[]>([])

  useEffect(() => {
    if (Object.keys(toastData).length) {
      const temp: any = [...toastList, toastData]
      setToastList(temp)
    }
  }, [toastData])

  const styles = useStyles()

  const deleteToast = (id: any) => {
    const index = toastList.findIndex(e => e.id === id)
    toastList.splice(index, 1)
    setToastList([...toastList])
  }

  return (
    <>
      {toastList.length > 0 && (
        <div className={`${styles.NotificationContainer} ${styles.TopRight} ${styles.FlexColumn}`}>
          {toastList.map(({ id, message, status }): any => {
            return (
              <div className={`${styles.FlexAround} ${styles.ToastBox}`}>
                <div className={styles.Flex}>
                  <img className={styles.Margin} src={`/icons/${status}Toast.svg`} alt='WarningToast' />
                  <span>{message}</span>
                </div>
                <span onClick={() => deleteToast(id)}>X</span>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
export default Toast
