import { cloneDeep } from 'lodash'

const SET_EXTRA_CREDIT = 'SET_EXTRA_CREDIT'

export const setExtraCredit = (payload: object) => ({ type: SET_EXTRA_CREDIT, payload })

const INITIAL_STATE = {
  totalExtraCredit: 0,
  usedExtraCredit: 0,
}

type Reducer = (
  state: typeof INITIAL_STATE,
  action: { type: string; payload: Partial<typeof INITIAL_STATE> }
) => Partial<typeof INITIAL_STATE>
const extraCreditReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXTRA_CREDIT:
      return { ...cloneDeep(state), ...action.payload }
    default:
      return state
  }
}

export default extraCreditReducer
