const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'

export const login = (payload: object) => ({ type: LOGIN, payload })
export const logout = () => ({ type: LOGOUT })

const INITIAL_AUTH_STATE = {
  isLoggedIn: sessionStorage.getItem('isLoggedIn') === 'true',
  token: sessionStorage.getItem('token'),
  refreshToken: sessionStorage.getItem('refreshToken'),
}
export type IAuth = typeof INITIAL_AUTH_STATE

type Reducer = (state: IAuth, action: { type: string; payload: Partial<IAuth> }) => Partial<IAuth>
const authReducer: Reducer = (state = INITIAL_AUTH_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      sessionStorage.setItem('isLoggedIn', 'true')
      sessionStorage.setItem('token', action.payload.token as string)
      sessionStorage.setItem('refreshToken', action.payload.refreshToken as string)
      return { ...state, isLoggedIn: true, token: action.payload.token }
    case LOGOUT:
      sessionStorage.removeItem('isLoggedIn')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('room_id')
      sessionStorage.removeItem('refreshToken')
      return { ...state, isLoggedIn: false, token: null }
    default:
      return state
  }
}

export default authReducer
