import type { IPlan, IAddon } from '../../types'

const SET_PRICING = 'SET_PRICING'
export const setPricing = (payload: object) => ({ type: SET_PRICING, payload })

const INITIAL_STATE = {
  plans: null as null | IPlan[],
  addons: null as null | IAddon[],
}

type Reducer = (
  state: typeof INITIAL_STATE,
  action: { type: string; payload: Partial<typeof INITIAL_STATE> }
) => Partial<typeof INITIAL_STATE>
const pricingReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PRICING:
      return action.payload
    default:
      return state
  }
}

export default pricingReducer
