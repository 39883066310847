import { createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import appReducer from './reducers/app'
import authReducer from './reducers/auth'
import userReducer from './reducers/user'
import companyReducer from './reducers/company'
import pricingReducer from './reducers/pricing'
import usersReducer from './reducers/users'
import extraCreditReducer from './reducers/extraCredit'
import pageReducer from './reducers/page'
import translatorReducer from './reducers/translator'
import dubbedVideoDetailsReducer from './reducers/dubbedVideoDetails'

const store = createStore(
  combineReducers({
    app: appReducer,
    auth: authReducer,
    user: userReducer,
    company: companyReducer,
    pricing: pricingReducer,
    users: usersReducer,
    extraCredit: extraCreditReducer,
    translator: translatorReducer,
    dubbedVideoDetails: dubbedVideoDetailsReducer,
    ...pageReducer,
  }),
  composeWithDevTools()
)

export default store
