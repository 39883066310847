import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as StoreProvider } from 'react-redux'
import Toast from './pages/Dashboard/_layout/components/Toast'
import './styles/globalStyles.css'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './styles/reactTostify.css'

import 'semantic-ui-css/semantic.min.css'

import RootRouter from './routes'
import store from './store'

function App() {
  return (
    <>
      <HelmetProvider>
        <StoreProvider store={store}>
          <ToastContainer theme='colored' hideProgressBar icon={false} closeButton={false} autoClose={3000} />
          <Toast />
          <RootRouter />
        </StoreProvider>
      </HelmetProvider>
    </>
  )
}

export default App
