import { cloneDeep } from 'lodash'

const entities = ['voices', 'scripts', 'invoices', 'filteredVoices', 'youtubeImports', 'dubbedVideos'] as const

type ActionTypes = 'SET' | 'UPDATE' | 'RESET' | 'REFRESH'
const getType = (type: ActionTypes, entity: typeof entities[number]) => [type, entity].join('_').toUpperCase()

type ActionCreator = (
  entity: typeof entities[number],
  payload?: Partial<typeof INITIAL_STATE>
) => { type: string; payload?: Partial<typeof INITIAL_STATE> }
export const setPage: ActionCreator = (entity, payload) => ({ type: getType('SET', entity), payload })
export const updatePage: ActionCreator = (entity, payload) => ({ type: getType('UPDATE', entity), payload })
export const resetPage: ActionCreator = entity => ({ type: getType('RESET', entity) })
export const refreshPage: ActionCreator = entity => ({ type: getType('REFRESH', entity) })

type Item = { _id: string; [key: string]: any }
const INITIAL_STATE = {
  data: null as null | Item[],
  scriptFetching: false,
  voiceFetching: false,
  limit: 10,
  currentPage: 1,
  totalItems: 0,
  filteredItemsCount: 0,
  totalPages: 0,
  refreshCount: 0,
  filters: {},
  meta: { voices: [] },
  youtubeImportsFetching: false,
  dubbedVideosFetching: false,
}

type Reducer = (
  state: typeof INITIAL_STATE,
  action: { type: ActionTypes; payload: Partial<typeof INITIAL_STATE> }
) => typeof INITIAL_STATE
type ReducerCreator = (entity: typeof entities[number]) => Reducer
const getReducer: ReducerCreator =
  entity =>
  (state = cloneDeep(INITIAL_STATE), action) => {
    switch (action.type) {
      case getType('SET', entity): {
        return { ...cloneDeep(state), ...action.payload }
      }
      case getType('UPDATE', entity): {
        return { ...cloneDeep(state), ...action.payload }
      }
      case getType('RESET', entity): {
        return cloneDeep(INITIAL_STATE)
      }
      case getType('REFRESH', entity): {
        return { ...cloneDeep(state), refreshCount: state.refreshCount + 1 }
      }
      default: {
        return cloneDeep(state)
      }
    }
  }

const objectReducers = {} as Record<string, Reducer>
entities.forEach(entity => {
  objectReducers[entity] = getReducer(entity)
})

export default objectReducers
