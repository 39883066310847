import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import Spinner from '../pages/Auth/Spinner'

const DashboardLayout = React.lazy(() => import('../pages/Dashboard/_layout'))
const Overview = React.lazy(() => import('../pages/Dashboard/Overview'))
const Invoices = React.lazy(() => import('../pages/Dashboard/Invoices'))
const FAQs = React.lazy(() => import('../pages/Dashboard/FAQs'))
const YouTubeAccess = React.lazy(() => import('../pages/Dashboard/YoutubeAccess'))
const VideoEditor = React.lazy(() => import('../pages/Dashboard/VideoEditor'))

const DashboardRouter = () => {
  return (
    <>
      <Suspense fallback={<Spinner />}>
        <DashboardLayout
          render={({ user, company }) => (
            <Switch>
              <Route path='/faqs' exact>
                <Suspense fallback={<div>Loading...</div>}>
                  <FAQs />
                </Suspense>
              </Route>
              <Route path='/account' exact>
                <Suspense fallback={<Spinner />}>
                  <Invoices user={user} company={company} />
                </Suspense>
              </Route>
              <Route path='/video-dubbing' exact>
                <Suspense fallback={<Spinner />}>
                  <YouTubeAccess user={user} company={company} />
                </Suspense>
              </Route>
              <Route path='/edit-video/:videoId' exact>
                <Suspense fallback={<Spinner />}>
                  <VideoEditor company={company} isWritable={true} />
                </Suspense>
              </Route>

              <Route path='/' exact>
                <Suspense fallback={<Spinner />}>
                  <Overview user={user} />
                </Suspense>
              </Route>
            </Switch>
          )}
        />
      </Suspense>
    </>
  )
}

export default DashboardRouter
