import '../../../styles/authStyle/confirmation/confirmation.css'
import EmailImg from '../../../assets/images/email.png'

// confirming Password
function Confirmation() {
  return (
    <>
      <div className='backgrounds'></div>
      <div className='headContainers'>
        <div className='box'>
          <img src={EmailImg} className='images' alt='email'></img>
          <h1 className='title'>Check your email</h1>
          <p className='infos'>We have sent you an email with instructions to recover the password</p>
          <a href='/' className='btnss'>
            Return to sign in{' '}
          </a>
        </div>
      </div>
    </>
  )
}

export default Confirmation
